<template>
    <b-card no-body class="mb-0">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset="resetForm">
                <validation-provider name="Title" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="title" class="form-b-card-title">Title</b-card-title>
                        <b-form-input type="text" class="form-control" id="title" v-model="formData.title"
                            placeholder="Module Title" :state="getValidationState(validationContext)" required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <!-- <validation-provider name="Route" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="route" class="form-b-card-text">Route</b-card-title>
                        <b-form-input type="text" class="form-control" id="route" v-model="formData.route"
                            :state="getValidationState(validationContext)" required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider> -->

                <validation-provider name="Path" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="path" class="form-b-card-title">Path</b-card-title>
                        <b-form-input type="text" class="form-control" id="path" v-model="formData.path"
                            placeholder="/example-page" :state="getValidationState(validationContext)" required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <validation-provider name="Name" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="name" class="form-b-card-title">Name</b-card-title>
                        <b-form-input type="text" class="form-control" id="name" v-model="formData.name"
                            placeholder="example-page" :state="getValidationState(validationContext)" required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <validation-provider name="Component" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="component" class="form-b-card-title">Component</b-card-title>
                        <b-form-input type="text" class="form-control" id="component" v-model="formData.component"
                            placeholder="@/views/examplePage.vue" :state="getValidationState(validationContext)"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <validation-provider name="Page Title" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="pageTitle" class="form-b-card-title">Page Title</b-card-title>
                        <b-form-input class="form-control" id="pageTitle" v-model="formData.pageTitle"
                            placeholder="Example Page" :state="getValidationState(validationContext)" required>
                        </b-form-input>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <validation-provider name="Project Module" rules="required" #default="validationContext">
                    <div class="mb-1">
                        <b-card-title for="ProjectModuleId" class="form-b-card-title">Project Module</b-card-title>
                        <b-form-select v-model="formData.ProjectModuleId" :options="topModules" value-field="id"
                            text-field="title" :state="getValidationState(validationContext)" required>
                        </b-form-select>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </div>
                </validation-provider>

                <b-row>
                    <b-col class="d-flex justify-content-start">
                        <b-button type="reset" size="lg" variant="danger">Reset</b-button>
                    </b-col>
                    <b-col class="d-flex justify-content-end">
                        <b-button type="submit" size="lg" variant="primary">Submit</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardText,
    BForm,
    BFormSelect,
    BFormInput,
    BButton,
    BCardTitle,
    BFormInvalidFeedback,
    BRow,
    BCol,
} from "bootstrap-vue";
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import 'vue-select/dist/vue-select.css';
import useModule from "./useModule";
import router from '@/router';
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BCardText,
        BCard,
        BForm,
        BFormSelect,
        BFormInput,
        BButton,
        BCardTitle,
        BFormInvalidFeedback,
        BRow,
        BCol,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
        };
    },
    setup() {
        const blankFormData = {
            title: null,
            path: null,
            name: null,
            component: null,
            pageTitle: null,
            ProjectModuleId: null,
        };
        const formData = ref(blankFormData);
        const resetuserData = () => {
            formData.value = { ...blankFormData };
        };
        const { topModules, createModuleFeature } = useModule();
        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData);
        return {
            formData,
            topModules,
            createModuleFeature,
            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
    methods: {
        async submitForm() {
                const success = await this.createModuleFeature(this.formData);
                if (success) {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Module Created Successfully`,
                            icon: "CoffeeIcon",
                            variant: "success",
                        },
                    });
                    router.push({ name: 'modules' });
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Module Creation Failed`,
                            icon: "CoffeeIcon",
                            variant: "danger",
                        },
                    });
                }
          
        },

    }
};
</script>

<style></style>